<template>

    <div>
        <div class="vx-row" v-if="item">
    <div class="vx-col w-full lg:w-1/1">
      <!-- ABOUT CARD -->
      <vx-card :title="`Detail ${$i18n.t('Equipment')}`" class="mt-base">
        <!-- ACTION SLOT -->
        <template slot="actions">
            <div class="flex gap-2">
                <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'equipment' })">
                    Kembali
                </vs-button>
                <vs-button color="warning"  icon="edit" type="border" @click="$router.push({ name: 'equipment-edit' })">
                    Edit
                </vs-button>
            </div>
        </template>
        <h6 class="font-bold mb-1">Title : <span class="font-medium">{{ item.equip_name }}</span></h6>
        <h6 class="font-bold mb-1">Brand : <span class="font-medium">{{ item.brand.brand_name }}</span></h6>
        <h6 class="font-bold mb-1">Customer : <span class="font-medium">{{ item.customer }}</span></h6>
        <!-- <div v-for="(attr, index) in item.attribute" :key="index">
            <h6 class="font-bold mb-1 capitalize">{{ attr.key }} : <span class="font-medium">{{ attr.value }}</span></h6>
        </div> -->
        <div class="vx-row mt-5">
            <div class="vx-col w-full lg:w-1/2">
            <h6 class="font-bold my-1">Equipment In</h6>
              <div class="vx-row mt-5">
                  <div class="vx-col w-full lg:w-1/2">
                      <img :src="item.equip_entry.picture" class="picture w-full">
                    </div>
                    <div class="vx-col w-full lg:w-1/2 my-2">
                        <h6 class="font-bold mb-1">Serial Number : <span class="font-medium">{{ item.equip_entry.serial_number ? item.equip_entry.serial_number : '-' }}</span></h6>
                        <h6 class="font-bold mb-1">Date : <span class="font-medium">{{ item.equip_entry.date }}</span></h6>
                        <h6 class="font-bold mb-1">Pic : <span class="font-medium">{{ item.equip_entry.pic_name }}</span></h6>
                        <div class="flex gap-2">
                            <h6 class="font-bold mb-1">Kelengkapan : </h6>
                            <p>{{ item.equip_entry.completeness }}</p>
                        </div>
                        <div class="flex gap-2">
                            <h6 class="font-bold mb-1">Note : </h6>
                            <p>{{ item.equip_entry.notes }}</p>
                        </div>
                    </div>
            </div>
          </div>
          <div class="vx-col w-full lg:w-1/2" v-if="item.equip_exit != null">
              <h6 class="font-bold my-5 lg:my-1">Equipment Out</h6>
              <div class="vx-row mt-5">
                  <div class="vx-col  w-full lg:w-1/2">
                      <img :src="item.equip_exit.picture" class="picture w-full">
                    </div>
                    <div class="vx-col  w-full lg:w-1/2 my-2">
                        <h6 class="font-bold mb-1">Serial Number : <span class="font-medium">{{ item.equip_exit.serial_number ? item.equip_exit.serial_number : '-' }}</span></h6>
                        <h6 class="font-bold mb-1">Date : <span class="font-medium">{{ item.equip_exit.date }}</span></h6>
                        <h6 class="font-bold mb-1">Pic : <span class="font-medium">{{ item.equip_exit.pic_name }}</span></h6>
                        <div class="flex gap-2">
                            <h6 class="font-bold mb-1">Kelengkapan : </h6>
                            <p>{{ item.equip_exit.completeness }}</p>
                        </div>
                        <div class="flex gap-2">
                            <h6 class="font-bold mb-1">Note : </h6>
                            <p>{{ item.equip_exit.notes }}</p>
                        </div>
                    </div>
            </div>
          </div>
        </div>


      </vx-card>

    </div>
  </div>
    </div>
  
  </template>
  
  <script>
import storage from '@/storage.js'
import moment from 'moment'

  
  
  export default {
    name: 'ShowSparepartVue',
    computed: {
        encrypt () {
        return this.$secure.encrypt(this.id);
        },

        isDetail(){
        if(this.$route.params.id){
            return true;
        }
        return false;
        }
    },
    data() {
        return {
            id: '',
            item: []
        }
    },
    methods: {
        getData(){
            this.$store.dispatch("equipment/showEquipment", this.id)
            .then((resp) => {
                this.item = resp.data.values
                this.item.equip_entry.picture = storage.equipment + this.item.equip_entry.picture
                this.item.equip_entry.date = moment(this.item.equip_entry.date).format("DD MMM YYYY HH:mm [WIB]")
                if (this.item.equip_exit) {
                    this.item.equip_exit.picture = storage.equipment + this.item.equip_exit.picture
                    this.item.equip_exit.date = moment(this.item.equip_exit.date).format("DD MMM YYYY HH:mm [WIB]")
                }
            }).catch(
                err => {
                    this.$vs.notify({
                    title:'Opps something error',
                    text: err.data.message,
                    color:'danger'
                    })
                }
            )
        }
    },
    created () {
      if(this.$route.params.id){
        this.id = this.$secure.decrypt(this.$route.params.id);
      }
      this.getData();
    }
  }
  
  </script>
  
  <style lang="scss">
  #page-user-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
  .picture {
  width: 200px;
  height: 250px;
}
  </style>
  